import React from 'react'
import {VscFilePdf, VscFileZip, VscFile} from 'react-icons/vsc'

const FileIcon = ({children}) => {
    return <span className="text-4xl">{children}</span>
}

const FileItem = (props) => {
    const {file, children, defaultImageSrc, className} = props
    const {type, name} = file

    const renderThumbnail = () => {
        const isImageFile = type.split('/')[0] === 'image'

        if (isImageFile) {
            return (
                <img
                    className="upload-file-image"
                    src={defaultImageSrc ?? URL.createObjectURL(file)}
                    alt={`file preview ${name}`}
                />
            )
        }

        if (type === 'application/zip') {
            return (
                <FileIcon>
                    <VscFileZip />
                </FileIcon>
            )
        }

        if (type === 'application/pdf') {
            return (
                <FileIcon>
                    <VscFilePdf />
                </FileIcon>
            )
        }

        return (
            <FileIcon>
                <VscFile />
            </FileIcon>
        )
    }

    return (
        <div className={`upload-file ${className}`}>
            <div className="flex">
                <div className="upload-file-thumbnail">{renderThumbnail()}</div>
                <div className="upload-file-info">
                    <h6 className="upload-file-name word-break">{name}</h6>
                </div>
            </div>
            {children}
        </div>
    )
}

export default FileItem
