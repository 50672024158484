import {combineReducers} from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import club from './adminClubSlice'

const reducer = combineReducers({
    session,
    user,
    club,
})

export default reducer
