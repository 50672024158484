import React from 'react'
import {HiCheck} from 'react-icons/hi'

import {Avatar} from 'components/ui'

const SelectOptionWithAvatar = ({innerProps, label, data, isSelected}) => {
    const baseStyles = 'flex items-center justify-between cursor-pointer p-2'
    const selectedStyles = 'bg-gray-100 dark:bg-gray-500'
    const notSelectedStyles = 'hover:bg-gray-50 dark:hover:bg-gray-600'

    return (
        <div className={`${baseStyles} ${isSelected ? selectedStyles : notSelectedStyles}`} {...innerProps}>
            <div className="flex items-center">
                {data.logo ? <Avatar shape="circle" size={20} src={data.logo} /> : <div className="w-5" />}
                <span className="ml-2 rtl:mr-2">{label}</span>
            </div>
            {isSelected && <HiCheck className="text-xl" />}
        </div>
    )
}

export default SelectOptionWithAvatar
