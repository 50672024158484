export const WORKOUT_TABS = {ALL: 'All', DRAFTS: 'Unpublished'}

export const WORKOUT_LEVEL = {
    BEGINNER: 'Beginner',
    INTERMEDIATE: 'Intermediate',
    ADVANCED: 'Advanced',
}

export const TRANING_TYPE = {
    STEADY_STATE: {value: 'SteadyState', label: 'Steady State'},
    RATE_PYRAMID: {value: 'RatePyramid', label: 'Rate Pyramid'},
    TECHNIQUE: {value: 'Technique', label: 'Technique'},
    INTERVALS: {value: 'Intervals', label: 'Intervals'},
    SPRINTS: {value: 'Sprints', label: 'Sprints'},
    POWER_INTERVALS: {value: 'PowerIntervals', label: 'Power Intervals'},
    HYBRID: {value: 'Hybrid', label: 'Hybrid'},
    EMOM: {value: 'EMOM', label: 'EMOM'},
    HIIT: {value: 'HIIT', label: 'HIIT'},
    METCON: {value: 'METCON', label: 'METCON'},
    AEROBIC: {value: 'Aerobic', label: 'Aerobic'},
    ANAERIBIC: {value: 'Anaerobic', label: 'Anaerobic'},
    TABATA: {value: 'Tabata', label: 'Tabata'},
    ENDURANCE: {value: 'Endurance', label: 'Endurance'},
    LACTATE_THRESHOLD: {value: 'LactateThreshold', label: 'Lactate Threshold'},
    FARTLEK: {value: 'Fartlek', label: 'Fartlek'},
    DISTANCE_CLASSIC: {value: 'DistanceClassic', label: 'Distance Classic'},
    DISTANCE_CHALLENGE: {value: 'DistanceChallenge', label: 'Distance Challenge'},
    DISTANCE_WORKOUT: {value: 'RecoveryWorkout', label: 'Distance Workout'},
    RACE_SIMULATION: {value: 'RaceSimulation', label: 'Race Simulation'},
    RESISTANCE_TRAINING: {value: 'ResistanceTraining', label: 'Resistance Training'},
    CROSS_TRAINING: {value: 'CrossTraining', label: 'Cross Training'},
    ENDURANCE_SPRINTS: {value: 'EnduranceSprints', label: 'Endurance Sprints'},
    AMRAP: {value: 'AMRAP', label: 'AMRAP'},
    TEMPO_TRAINING: {value: 'TempoTraining', label: 'Tempo Training'},
}

export const ACTIVITY_TYPE = {
    DISTANCE: 'Distance',
    CALORIES: 'Calories',
    DURATION: 'Time',
}

export const SINGLE_WORKOUT_TYPE = 'Single'

export const FIXED_INTERVAL_WORKOUT_TYPE = 'FixedInterval'

export const WITHOUT_INDEX_WORKOUT_TYPES = [SINGLE_WORKOUT_TYPE, FIXED_INTERVAL_WORKOUT_TYPE]

export const PM5WORKOUT_TYPE = {
    SINGLE: {value: SINGLE_WORKOUT_TYPE, label: SINGLE_WORKOUT_TYPE},
    FIXED_INTERVAl: {value: FIXED_INTERVAL_WORKOUT_TYPE, label: 'Fixed Interval'},
    VARIABLE_INTERVAL: {value: 'VariableInterval', label: 'Variable Interval'},
}

export const DISPLAYED_METRIC = {
    DISTANCE: 'Distance',
    CALORIES: 'Calories',
    DURATION: 'Duration',
}
