import React, {memo, useMemo, lazy, Suspense} from 'react'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'

import appConfig from 'configs/app.config'
import {Loading} from 'components/shared'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
} from 'constants/theme.constant'
import useDirection from 'utils/hooks/useDirection'
import {getIsAuthUser} from 'store/auth/selectors'
import {getLayoutType} from 'store/theme/selectors'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
}

const Layout = () => {
    const location = useLocation()
    const layoutType = useSelector(getLayoutType)
    const authenticated = useSelector(getIsAuthUser)

    useDirection()

    const AppLayout = useMemo(() => {
        if (appConfig.externalRoutes.includes(location.pathname)) {
            return lazy(() => import('./ExternalRoutesLayout'))
        }

        if (authenticated) {
            return layouts[layoutType]
        }
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, authenticated, location.pathname])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    )
}

export default memo(Layout)
