import toast from './toast'
import Alert from '../Alert'

export const pushAlert = (type, message, options) => {
    toast.push(
        <Alert type={type} showIcon closable>
            {message}
        </Alert>,
        options,
    )
}
