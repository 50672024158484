export const ADMIN_PERMISSIONS = {
    CLUB_POSTS: 'ClubPostsManagement',
    CLUB_MEMBERS: 'ClubMembersManagement',
    EVENTS: 'EventsManagement',
    WORKOUTS: 'WorkoutsManagement',
    COMMENTS: 'CommentsManagement',
}

export const ADMIN_PERMISSIONS_LABELS = {
    [ADMIN_PERMISSIONS.CLUB_POSTS]: 'Club posts management',
    [ADMIN_PERMISSIONS.CLUB_MEMBERS]: 'Club members management',
    [ADMIN_PERMISSIONS.EVENTS]: 'Events management',
    [ADMIN_PERMISSIONS.WORKOUTS]: 'Workouts management',
    [ADMIN_PERMISSIONS.COMMENTS]: 'Comments management',
}

export const ADMIN_PERMISSIONS_FLAGS = {
    [ADMIN_PERMISSIONS.CLUB_POSTS]: 1,
    [ADMIN_PERMISSIONS.CLUB_MEMBERS]: 2,
    [ADMIN_PERMISSIONS.EVENTS]: 4,
    [ADMIN_PERMISSIONS.WORKOUTS]: 8,
    [ADMIN_PERMISSIONS.COMMENTS]: 16,
}

const makeCheckbox = (value) => ({value, label: ADMIN_PERMISSIONS_LABELS[value] ?? ''})

export const permissionsCheckboxes = [
    makeCheckbox(ADMIN_PERMISSIONS.CLUB_POSTS),
    makeCheckbox(ADMIN_PERMISSIONS.CLUB_MEMBERS),
    makeCheckbox(ADMIN_PERMISSIONS.EVENTS),
    makeCheckbox(ADMIN_PERMISSIONS.WORKOUTS),
    makeCheckbox(ADMIN_PERMISSIONS.COMMENTS),
]
