import React from 'react'

const AdminViewWithoutClub = () => {
    return (
        <div className="h-full flex items-center justify-center">
            <h6 className="empty-table-text">You are not assigned to any club</h6>
        </div>
    )
}

export default AdminViewWithoutClub
