import {REPORTS_TYPES} from 'constants/reports.constant'

import ApiService from './ApiService'

export async function getReports({type, offset, query, limit}) {
    const pagination = `&Limit=${limit}&Offset=${offset ?? 0}`
    const search = query ? `&Search=${query}` : ''

    return ApiService.fetchData({
        url: `/admin-reports?Type=${type}${pagination}${search}`,
        method: 'get',
    })
}

export async function getUserReports({userId, offset, limit}) {
    const pagination = `Limit=${limit}&Offset=${offset ?? 0}`

    return ApiService.fetchData({
        url: `/admin-users/${userId}/reports?${pagination}`,
        method: 'get',
    })
}

export async function getBlockedUsers({offset, query, limit}) {
    const common = 'IsBlocked=true&Order.Key=BlockedAt&Order.Direction=Desc'
    const pagination = `&Limit=${limit}&Offset=${offset}`
    const search = query ? `&Search=${query}` : ''

    return ApiService.fetchData({
        url: `/admin-users?${common}${pagination}${search}`,
        method: 'get',
    })
}

export async function getUnreadReportsCount(type) {
    return ApiService.fetchData({
        url: `/admin-reports/unread?Type=${type}`,
        method: 'get',
    })
}

export async function getAllUnreadReportsCount() {
    return Promise.allSettled([
        getUnreadReportsCount(REPORTS_TYPES.POST),
        getUnreadReportsCount(REPORTS_TYPES.COMMENT),
    ]).then(([userPostsRes, userCommentsRes, blockUsersRes]) => ({
        [REPORTS_TYPES.POST]: userPostsRes.status === 'fulfilled' ? userPostsRes.value.data.count : 0,
        [REPORTS_TYPES.COMMENT]: userCommentsRes.status === 'fulfilled' ? userCommentsRes.value.data.count : 0,
    }))
}

export async function declineReport(id) {
    return ApiService.fetchData({
        url: `/admin-reports/${id}`,
        method: 'put',
    })
}

export async function deleteUserPostFromReports(reportId, userPostId) {
    return ApiService.fetchData({
        url: `/admin-reports/${reportId}/userpost/${userPostId}`,
        method: 'delete',
    })
}
