export const COMMENTS_TYPES = {
    CLUB_POST: 'clubPost',
    EVENT: 'event',
    WORKOUT: 'workout',
    USER_POST: 'userPost',
}

export const COMMENTS_TABS = [
    {
        value: COMMENTS_TYPES.CLUB_POST,
        label: 'Club posts',
    },
    {
        value: COMMENTS_TYPES.EVENT,
        label: 'Events',
    },
    {
        value: COMMENTS_TYPES.WORKOUT,
        label: 'Workouts',
    },
    {
        value: COMMENTS_TYPES.USER_POST,
        label: 'User posts',
    },
]

export const COMMENTS_TAGS = {
    REPLY: 'Reply',
    ORIGINAL: 'Original',
}
