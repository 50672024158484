import React from 'react'

const TableTotalCount = ({loading, page, pageSize, total, dataLength}) => {
    const offset = (page - 1) * pageSize

    return (
        <div className="font-semibold">
            {!loading ? `Showing ${offset + 1} to ${offset + dataLength} of ${total} entries` : 'Showing ...'}
        </div>
    )
}

export default TableTotalCount
