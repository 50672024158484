import React from 'react'

import {Button, Dialog} from 'components/ui'

const ConfirmDialogV2 = (props) => {
    const {title, children, onCancel, onConfirm, cancelText, confirmText, disabled, ...rest} = props

    const handleCancel = () => {
        onCancel?.()
    }

    const handleConfirm = () => {
        onConfirm?.()
    }

    return (
        <Dialog className="confirm-dialog" contentClassName="confirm-dialog-content" {...rest}>
            <p className="confirm-dialog-v2-title">{title}</p>
            <div className="confirm-dialog-v2-text">{children}</div>
            <div className="flex justify-end gap-3">
                <Button className="font-bold" onClick={handleCancel} disabled={disabled}>
                    {cancelText}
                </Button>
                <Button className="font-bold" variant="solid" onClick={handleConfirm} disabled={disabled}>
                    {confirmText}
                </Button>
            </div>
        </Dialog>
    )
}

ConfirmDialogV2.defaultProps = {
    cancelText: 'Cancel',
    confirmText: 'Confirm',
}

export default ConfirmDialogV2
