export const ACCEPT_IMAGES_TYPES = ['image/png', 'image/jpg', 'image/jpeg']

export const beforeUpload = (newFiles, options) => {
    const acceptTypes = options?.acceptTypes ?? ACCEPT_IMAGES_TYPES
    const typeErrMsg = options?.typeErrMsg ?? 'Image format is invalid. Please select JPG, JPEG or PNG file'
    const maxMbSize = options?.maxMbSize ?? 5
    const sizeErrMsg = `Maximum image size is ${maxMbSize}Mb`

    const maxImageSize = Math.pow(1024, 2) * maxMbSize

    if (!acceptTypes.includes(newFiles[0].type)) {
        return typeErrMsg
    }

    if (newFiles[0].size > maxImageSize) {
        return sizeErrMsg
    }
    return true
}
