import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

import {getAllUnreadReportsCount} from 'api-services/ReportsService'
import {REPORTS_TYPES} from 'constants/reports.constant'

export const getUnreadReportsCount = createAsyncThunk('reports/getUnreadReportsCount', async () => {
    const response = await getAllUnreadReportsCount()
    return response
})

const TYPES_ARR = Object.values(REPORTS_TYPES)

const initialState = {
    countLoading: {
        [REPORTS_TYPES.POST]: false,
        [REPORTS_TYPES.COMMENT]: false,
    },
    reportsCount: {
        [REPORTS_TYPES.POST]: 0,
        [REPORTS_TYPES.COMMENT]: 0,
    },
}

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    extraReducers: {
        [getUnreadReportsCount.pending]: (state) => {
            state.countLoading = TYPES_ARR.reduce((acc, type) => ({...acc, [type]: true}), {})
        },
        [getUnreadReportsCount.fulfilled]: (state, action) => {
            state.reportsCount = action.payload
            state.countLoading = TYPES_ARR.reduce((acc, type) => ({...acc, [type]: false}), {})
        },
        [getUnreadReportsCount.rejected]: (state) => {
            state.countLoading = TYPES_ARR.reduce((acc, type) => ({...acc, [type]: false}), {})
        },
    },
})

export const selectors = {
    countLoading: (store) => store.reports.countLoading,
    postsUnraedCount: (store) => store.reports.reportsCount[REPORTS_TYPES.POST],
    commentsUnraedCount: (store) => store.reports.reportsCount[REPORTS_TYPES.COMMENT],
    getTotalCount: (store) => Object.values(store.reports.reportsCount).reduce((acc, v) => acc + v, 0),
}

export default reportsSlice.reducer
