import React, {useState, useRef, useMemo} from 'react'
import PropTypes from 'prop-types'
import {Field, Form, Formik} from 'formik'
import {HiOutlineFilter} from 'react-icons/hi'
import SelectField from '../../views/WorkoutCreate/WorkoutForm/SelectField'

import {TRANING_TYPE} from 'constants/workout.constant'

import {Button, Checkbox, FormContainer, Drawer} from 'components/ui'
import useCategoryOptions from 'api-services/hooks/useCategoryOptions'

const FilterCheckboxesDrawer = ({
    checkboxes,
    initialFilters,
    setFilter,
    clearFilter,
    filterByMetatag = false,
    filterByTrainingType = false,
    isWorkoutFilter = false,
}) => {
    const formRef = useRef()

    const [isOpen, setIsOpen] = useState(false)

    const {categoryOptions, isLoadingCateforyOptions} = useCategoryOptions(isWorkoutFilter)
    const trainingTypeOptions = useMemo(() => Object.values(TRANING_TYPE), [])

    const openFilter = () => setIsOpen(true)
    const onCloseFilter = () => setIsOpen(false)

    const onClearFilters = () => {
        clearFilter()
        onCloseFilter()
    }

    const handleSubmit = () => formRef.current.handleSubmit()

    const onSubmit = (values) => {
        setFilter(values)
        onCloseFilter()
    }

    const onChange = (form, fieldName, name, isUnique, value) => {
        if (isUnique) {
            form.setFieldValue(fieldName, value ? [name] : [])
            return
        }

        form.setFieldValue(
            fieldName,
            value ? [...form.values[fieldName], name] : form.values[fieldName].filter((v) => v !== name),
        )
    }

    const showClearAll = Object.values(initialFilters).some((f) => f.length > 0)

    return (
        <div className="flex items-center gap-1">
            <Button type="button" size="sm" icon={<HiOutlineFilter />} onClick={openFilter}>
                Filter
            </Button>
            {showClearAll && (
                <Button type="button" size="xs" variant="plain" onClick={onClearFilters}>
                    Clear all
                </Button>
            )}

            <Drawer
                title="Filters"
                isOpen={isOpen}
                onClose={onCloseFilter}
                footer={
                    <div className="flex gap-2 justify-end w-full">
                        <Button type="button" onClick={onClearFilters}>
                            Clear all
                        </Button>
                        <Button type="button" onClick={handleSubmit} className="w-32" variant="solid">
                            Apply
                        </Button>
                    </div>
                }
            >
                <Formik innerRef={formRef} enableReinitialize initialValues={initialFilters} onSubmit={onSubmit}>
                    <Form>
                        <FormContainer>
                            {checkboxes.map(({fieldTitle, fieldName, checkboxValues, isUnique}) => (
                                <div key={fieldTitle}>
                                    <h6 className="mb-4 font-bold">{fieldTitle}</h6>
                                    <Field name={fieldName}>
                                        {({field, form}) => (
                                            <Checkbox.Group vertical value={field.value}>
                                                {checkboxValues.map((v) => (
                                                    <Checkbox
                                                        key={v.value}
                                                        className="mb-3"
                                                        name={fieldName}
                                                        value={v.value}
                                                        onChange={onChange.bind(
                                                            null,
                                                            form,
                                                            fieldName,
                                                            v.value,
                                                            isUnique,
                                                        )}
                                                    >
                                                        {v.label}
                                                    </Checkbox>
                                                ))}
                                            </Checkbox.Group>
                                        )}
                                    </Field>
                                </div>
                            ))}
                            {filterByMetatag && (
                                <SelectField
                                    name="byMetaTag"
                                    options={categoryOptions}
                                    label="Meta tags"
                                    placeholder="Select meta tags"
                                    isLoading={isLoadingCateforyOptions}
                                    isMulti
                                    className="toolbar-filter-dropdown"
                                />
                            )}
                            {filterByTrainingType && (
                                <SelectField
                                    name="byTrainingType"
                                    options={trainingTypeOptions}
                                    label="Training type"
                                    placeholder={'Select training type'}
                                    isMulti
                                    className="toolbar-filter-dropdown"
                                />
                            )}
                        </FormContainer>
                    </Form>
                </Formik>
            </Drawer>
        </div>
    )
}

FilterCheckboxesDrawer.propTypes = {
    checkboxes: PropTypes.arrayOf(
        PropTypes.shape({
            fieldTitle: PropTypes.string.isRequired,
            fieldName: PropTypes.string.isRequired,
            checkboxValues: PropTypes.arrayOf(PropTypes.string).isRequired,
            isUnique: PropTypes.bool,
        }),
    ).isRequired,
    initialFilters: PropTypes.shape({}).isRequired,
    setFilter: PropTypes.func.isRequired,
    clearFilter: PropTypes.func.isRequired,
    filterByMetatag: PropTypes.bool,
    filterByTrainingType: PropTypes.bool,
    isWorkoutFilter: PropTypes.bool,
}

export default FilterCheckboxesDrawer
