import React from 'react'
import {motion, AnimatePresence} from 'framer-motion'

const MotionBlock = ({children}) => {
    const enterStyle = {opacity: 1, height: 'auto'}
    const exitStyle = {opacity: 0, height: 0}
    const initialStyle = exitStyle

    return (
        <AnimatePresence exitBeforeEnter>
            {Boolean(children) && (
                <motion.div
                    initial={initialStyle}
                    animate={enterStyle}
                    exit={exitStyle}
                    transition={{duration: 0.25, type: 'tween'}}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default MotionBlock
