import React from 'react'
import {Field, Form, Formik} from 'formik'
import {CiSearch} from 'react-icons/ci'
import {MdOutlineClear} from 'react-icons/md'

import {Input} from 'components/ui'

const initialValues = {search: ''}

const SearchInput = ({setQuery}) => {
    const onQueryChange = (values) => setQuery(values.search)
    const clearSearch = (resetForm) => {
        resetForm()
        setQuery('')
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onQueryChange}>
            {({values, resetForm}) => (
                <Form>
                    <Field
                        className="max-w-md md:w-52 search-input"
                        type="text"
                        autoComplete="off"
                        name="search"
                        placeholder="Search..."
                        component={Input}
                        size="md"
                        prefix={<CiSearch className="text-xl" />}
                        suffix={
                            values.search && (
                                <MdOutlineClear
                                    className="text-xl cursor-pointer"
                                    onClick={clearSearch.bind(null, resetForm)}
                                />
                            )
                        }
                    />
                </Form>
            )}
        </Formik>
    )
}

export default SearchInput
