export const REPORTS_TYPES = {
    POST: 'post',
    COMMENT: 'comment',
    BLOCKED_USERS: 'blockedUsers',
}

export const REPORTS_TABS = [
    {
        value: REPORTS_TYPES.POST,
        label: 'Reported posts',
    },
    {
        value: REPORTS_TYPES.COMMENT,
        label: 'Reported comments',
    },
    {
        value: REPORTS_TYPES.BLOCKED_USERS,
        label: 'Blocked users',
    },
]

export const REPORTS_STATUS = {
    NONE: 'None',
    DECLINED: 'Declined',
    CONFIRMED: 'Confirmed',
}
