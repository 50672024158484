import React from 'react'
import PropTypes from 'prop-types'
import {IoIosCopy} from 'react-icons/io'

import {Button, pushAlert} from 'components/ui'

const CopyButton = ({copyText, buttonText, className, icon}) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(copyText)
        pushAlert('success', 'Copy Success')
    }

    return (
        <Button
            variant="twoTone"
            size="xs"
            shape="round"
            icon={icon ?? <IoIosCopy />}
            onClick={handleCopy}
            className={className}
        >
            {buttonText ?? 'Copy'}
        </Button>
    )
}

CopyButton.propTypes = {
    copyText: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    className: PropTypes.string,
}

export default CopyButton
