import React from 'react'
import PropTypes from 'prop-types'
import {HiExclamation} from 'react-icons/hi'
import {FiInfo} from 'react-icons/fi'
import {TbCircleCheck} from 'react-icons/tb'
import {VscError} from 'react-icons/vsc'

const ICONS = {
    success: {
        color: 'text-emerald-400',
        icon: <TbCircleCheck />,
    },
    info: {
        color: 'text-blue-400',
        icon: <FiInfo />,
    },
    warning: {
        color: 'text-yellow-400',
        icon: <HiExclamation />,
    },
    danger: {
        color: 'text-red-400',
        icon: <VscError />,
    },
}

const StatusIcon = (props) => {
    const {type, custom, iconColor} = props

    const icon = ICONS[type]

    return <span className={`text-2xl ${iconColor || icon.color}`}>{custom || icon.icon}</span>
}

StatusIcon.defaultProps = {
    type: 'info',
}

StatusIcon.propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'warning', 'danger']),
    custom: PropTypes.node,
}

export default StatusIcon
