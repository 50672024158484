import ApiService from './ApiService'

export async function getCategoriesData(params) {
    const {offset, query, limit, isWorkout} = params

    const pagination = `Limit=${limit}&Offset=${offset}`
    const search = query ? `&Search=${query}` : ''
    const isWorkoutQuery = isWorkout ? '&IsWorkout=true' : ''

    return ApiService.fetchData({
        url: `/admin-categories?${pagination}${search}${isWorkoutQuery}`,
        method: 'get',
        data: null,
    })
}

export async function createCategory(data) {
    return ApiService.fetchData({
        url: '/admin-categories',
        method: 'post',
        data,
    })
}

export async function editCategory(id, data) {
    return ApiService.fetchData({
        url: `/admin-categories/${id}`,
        method: 'put',
        data,
    })
}

export async function deleteCategoryById(id) {
    return ApiService.fetchData({
        url: `/admin-categories/${id}`,
        method: 'delete',
    })
}

export async function duplicateCategoryById(id) {
    return ApiService.fetchData({
        url: `/admin-categories/${id}/copy`,
        method: 'put',
    })
}
