import DatePicker from './DatePicker'
import DatePickerRange from './DatePickerRange'
import DateTimepicker from './DateTimepicker'
import Calendar from './Calendar'
import RangeCalendar from './RangeCalendar'

DatePicker.DatePickerRange = DatePickerRange
DatePicker.DateTimepicker = DateTimepicker

export {Calendar, RangeCalendar}

export default DatePicker
