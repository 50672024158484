import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

import {getAllUnreadCommentsCount} from 'api-services/CommentsService'
import {COMMENTS_TYPES} from 'constants/comments.constant'

export const getUnreadCommentsCount = createAsyncThunk('comments/getUnreadCommentsCount', async () => {
    const response = await getAllUnreadCommentsCount()
    return response
})

const TYPES_ARR = Object.values(COMMENTS_TYPES)

const initialTableData = {
    total: 10,
    page: 1,
    query: '',
    pageSize: 10,
}

const initialState = {
    countLoading: {
        [COMMENTS_TYPES.CLUB_POST]: false,
        [COMMENTS_TYPES.EVENT]: false,
        [COMMENTS_TYPES.WORKOUT]: false,
        [COMMENTS_TYPES.USER_POST]: false,
    },
    commentsCount: {
        [COMMENTS_TYPES.CLUB_POST]: 0,
        [COMMENTS_TYPES.EVENT]: 0,
        [COMMENTS_TYPES.WORKOUT]: 0,
        [COMMENTS_TYPES.USER_POST]: 0,
    },
    commentsList: [],
    tableData: initialTableData,
}

export const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.tableData.page = action.payload
        },
        setQuery: (state, action) => {
            state.tableData.query = action.payload
            state.tableData.page = 1
        },
        onTabChange: (state) => {
            state.tableData.query = ''
            state.tableData.page = 1
        },
        setCommentsTable: (state, action) => {
            state.commentsList = action.payload.data
            state.tableData.total = action.payload.pagination.totalCount
        },
        resetTable: (state) => {
            state.commentsList = []
            state.tableData = initialTableData
        },
    },
    extraReducers: {
        [getUnreadCommentsCount.pending]: (state) => {
            state.countLoading = TYPES_ARR.reduce((acc, type) => ({...acc, [type]: true}), {})
        },
        [getUnreadCommentsCount.fulfilled]: (state, action) => {
            state.commentsCount = action.payload
            state.countLoading = TYPES_ARR.reduce((acc, type) => ({...acc, [type]: false}), {})
        },
        [getUnreadCommentsCount.rejected]: (state) => {
            state.countLoading = TYPES_ARR.reduce((acc, type) => ({...acc, [type]: false}), {})
        },
    },
})

export const {setPage, setQuery, onTabChange, setCommentsTable, resetTable} = commentsSlice.actions

export const selectors = {
    // unread count
    countLoading: (store) => store.comments.countLoading,
    postsUnraedCount: (store) => store.comments.commentsCount[COMMENTS_TYPES.CLUB_POST],
    eventsUnraedCount: (store) => store.comments.commentsCount[COMMENTS_TYPES.EVENT],
    workoutsUnraedCount: (store) => store.comments.commentsCount[COMMENTS_TYPES.WORKOUT],
    achievementsUnraedCount: (store) => store.comments.commentsCount[COMMENTS_TYPES.USER_POST],
    getTotalCount: (store) => Object.values(store.comments.commentsCount).reduce((acc, v) => acc + v, 0),
    // list
    commentsList: (store) => store.comments.commentsList,
    page: (store) => store.comments.tableData.page,
    total: (store) => store.comments.tableData.total,
    pageSize: (store) => store.comments.tableData.pageSize,
    query: (store) => store.comments.tableData.query,
}

export default commentsSlice.reducer
