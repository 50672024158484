import axios from 'axios'

import appConfig from 'configs/app.config'
import {TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY} from 'constants/api.constant'
import {PERSIST_STORE_NAME} from 'constants/app.constant'
import {pushAlert} from 'components/ui'
import deepParseJson from 'utils/deepParseJson'
import store from 'store'
import {onSignOutSuccess, setNewTokens} from 'store/auth/sessionSlice'

const unauthorizedCode = [401]

const BaseService = axios.create({
    baseURL: appConfig.apiPrefix,
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
})

const interceptorsRequest = [
    (request) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)
        const accessToken = persistData.auth.session.accessToken

        if (accessToken) {
            request.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
        }

        return request
    },
    (err) => Promise.reject(err),
]

const interceptorsResponse = [
    (response) => response.data,
    (error) => {
        let dataObject = {}
        const {response} = error
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        const refreshToken = persistData.auth.session.refreshToken

        if (response) {
            dataObject = response.data
        }

        const statusCode = dataObject?.code || dataObject?.statusCode || (response && response.status)
        const isUnauth = unauthorizedCode.includes(statusCode) || statusCode === 'UNAUTHORIZED_ERROR'

        if (isUnauth && refreshToken) {
            let isRefreshed = false
            return axios
                .put(`${appConfig.apiPrefix}/admin-sessions`, {refreshToken})
                .then(({data}) => {
                    const {accessToken, refreshToken} = data.data
                    store.dispatch(setNewTokens({accessToken, refreshToken}))
                    error.config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
                    isRefreshed = true
                    // retry request
                    return axios.request(error.config).then((res) => res.data)
                })
                .catch((err) => {
                    if (!isRefreshed) {
                        pushAlert('danger', 'The current session has expired')
                        store.dispatch(onSignOutSuccess())
                    }
                    throw err
                })
        }

        return Promise.reject(dataObject)
    },
]

BaseService.interceptors.request.use(...interceptorsRequest)
BaseService.interceptors.response.use(...interceptorsResponse)

export default BaseService
