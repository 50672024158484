import React from 'react'
import {components} from 'react-select'

import {Avatar} from 'components/ui'

const {Control} = components

const SelectControlWithAvatar = ({children, ...props}) => {
    const selected = props.getValue()[0]

    return (
        <Control {...props}>
            {selected && selected.logo && (
                <Avatar className="ltr:ml-3 rtl:mr-3" shape="circle" size={20} src={selected.logo} />
            )}
            {children}
        </Control>
    )
}

export default SelectControlWithAvatar
