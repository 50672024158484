import {ROUTES} from 'constants/routes.constant'

const appConfig = {
    apiPrefix: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}`,
    authenticatedEntryPath: ROUTES.USER_MANAGEMENT,
    unAuthenticatedEntryPath: ROUTES.SIGN_IN,
    externalRoutes: [ROUTES.TERM_OF_USE, ROUTES.PRIVACY_POLICY, ROUTES.GREETING],
}

export default appConfig
