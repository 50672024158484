import React from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {PersistGate} from 'redux-persist/integration/react'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import store, {persistor} from 'store'
import history from './history'

const queryClient = new QueryClient()

function App() {
    document.title = 'Row Nation | Admin'

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter history={history}>
                        <Theme>
                            <Layout />
                        </Theme>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    )
}

export default App
