import React from 'react'
import PropTypes from 'prop-types'
import {BiSortDown, BiSortUp} from 'react-icons/bi'

import {Button} from 'components/ui'

const ToggleSortButton = ({isSortByAsc, onClick, disabled}) => {
    return (
        <Button
            size="sm"
            variant="plain"
            className="border-gray-500"
            icon={isSortByAsc ? <BiSortDown /> : <BiSortUp />}
            onClick={onClick}
            disabled={disabled}
        />
    )
}

ToggleSortButton.propTypes = {
    isSortByAsc: PropTypes.bool,
    onClick: PropTypes.func,
}

export default ToggleSortButton
