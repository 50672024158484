import {COMMENTS_TYPES} from 'constants/comments.constant'

import ApiService from './ApiService'

export async function getComments({type, offset, query, limit, parentCommentId}) {
    const pagination = `&Limit=${limit}&Offset=${offset ?? 0}`
    const search = query ? `&Search=${query}` : ''
    const parentQuery = parentCommentId ? `&ParentCommentId=${parentCommentId}` : ''

    return ApiService.fetchData({
        url: `/admin-comments?Type=${type}${pagination}${search}${parentQuery}`,
        method: 'get',
    })
}

export async function getCommentsForSpecificEntity({id, type, offset, limit}) {
    const typeQuery = `?Type=${type}`
    const pagination = `&Limit=${limit}&Offset=${offset ?? 0}`

    return ApiService.fetchData({
        url: `/admin-comments/${id}${typeQuery}${pagination}`,
        method: 'get',
    })
}

export async function getUnreadCommentsCount(type) {
    return ApiService.fetchData({
        url: `/admin-comments/unread?Type=${type}`,
        method: 'get',
    })
}

export async function getAllUnreadCommentsCount() {
    return Promise.allSettled([
        getUnreadCommentsCount(COMMENTS_TYPES.CLUB_POST),
        getUnreadCommentsCount(COMMENTS_TYPES.EVENT),
        getUnreadCommentsCount(COMMENTS_TYPES.WORKOUT),
        getUnreadCommentsCount(COMMENTS_TYPES.USER_POST),
    ]).then(([clubPostRes, eventRes, workoutRes, userPostRes]) => ({
        [COMMENTS_TYPES.CLUB_POST]: clubPostRes.status === 'fulfilled' ? clubPostRes.value.data.count : 0,
        [COMMENTS_TYPES.EVENT]: eventRes.status === 'fulfilled' ? eventRes.value.data.count : 0,
        [COMMENTS_TYPES.WORKOUT]: workoutRes.status === 'fulfilled' ? workoutRes.value.data.count : 0,
        [COMMENTS_TYPES.USER_POST]: userPostRes.status === 'fulfilled' ? userPostRes.value.data.count : 0,
    }))
}

export async function replyToComment(data) {
    return ApiService.fetchData({
        url: '/admin-comments',
        method: 'post',
        data,
    })
}

export async function removeComment(id, reportId) {
    const reportQuery = reportId ? `?reportId=${reportId}` : ''

    return ApiService.fetchData({
        url: `/admin-comments/${id}${reportQuery}`,
        method: 'delete',
    })
}
