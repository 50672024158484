import {createSlice} from '@reduxjs/toolkit'

export const initialState = null

export const adminClubSlice = createSlice({
    name: 'auth/club',
    initialState,
    reducers: {
        setClub: (_, action) => action.payload,
        clearAdminClub: () => initialState,
    },
})

export const {setClub, clearAdminClub} = adminClubSlice.actions

export default adminClubSlice.reducer
