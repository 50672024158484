import {ADMIN_PERMISSIONS} from './permissions.constant'

export const ROUTES = {
    // auth routes
    SIGN_IN: '/sign-in',
    FORGOT_PASSWORD: '/forgot-password',
    SET_NEW_PASSWORD: '/set-new-password',
    CREATE_PASSWORD: '/create-new-password',
    EXPIRED_LINK: '/expired-link',
    // protected routes
    USER_MANAGEMENT: '/users-management',
    USER_DETAILS: '/users-management/:id',
    ADMIN_MANAGEMENT: '/admins-management',
    ADMIN_DETAILS: '/admins-management/:id',
    CLUBS_MANAGEMENT: '/clubs-management',
    CLUB_DETAILS: '/clubs-management/:id',
    CLUB_EDIT: '/clubs-management/:id/edit',
    CLUBS_POSTS_MANAGEMENT: '/clubs-posts-management',
    CLUB_POSTS_BY_CLUB: '/clubs-posts-management/:clubId',
    CLUB_POST_NEW: '/clubs-posts-management/:clubId/post/new',
    CLUB_POST_DETAILS: '/clubs-posts-management/:clubId/post/:postId',
    CLUB_POST_EDIT: '/clubs-posts-management/:clubId/post/:postId/edit',
    EVENTS_MANAGEMENT: '/events-management',
    EVENT_NEW: '/events-management/new',
    EVENT_DETAILS: '/events-management/:eventId',
    EVENT_EDIT: '/events-management/:eventId/edit',
    WORKOUTS_MANAGEMENT: '/workouts-management',
    WORKOUT_CREATE: '/workouts-management/new',
    WORKOUT_DETAILS: '/workouts-management/:id',
    WORKOUT_EDIT: '/workouts-management/:id/edit',
    COMMENTS_MANAGEMENT: '/comments-management',
    COMMENTS_BY_TYPE: '/comments-management/:type/:id',
    REPORTS_MANAGEMENT: '/reports-management',
    BLOCKED_USER_MANAGEMENT: '/reports-management/blockedUsers/:userId',
    DASHBOARD: '/dashboard',
    PROGRAMS_MANAGEMENT: '/programs-management',
    PROGRAMS_CREATE: '/programs-management/new',
    PROGRAMS_DETAILS: '/programs-management/:id',
    PROGRAMS_EDIT: '/programs-management/:id/edit',
    CATEGORIES_MANAGEMENT: '/categories-management',
    // external routes
    TERM_OF_USE: '/term-of-use',
    PRIVACY_POLICY: '/privacy-policy',
    GREETING: '/greeting',
}

export const ADMIN_ALLOWED_ROUTES_BY_PERMISSIONS = [
    {key: ADMIN_PERMISSIONS.CLUB_MEMBERS, path: ROUTES.CLUBS_MANAGEMENT},
    {key: ADMIN_PERMISSIONS.CLUB_MEMBERS, path: ROUTES.CLUB_DETAILS},
    {key: ADMIN_PERMISSIONS.CLUB_POSTS, path: ROUTES.CLUBS_POSTS_MANAGEMENT},
    {key: ADMIN_PERMISSIONS.CLUB_POSTS, path: ROUTES.CLUB_POSTS_BY_CLUB},
    {key: ADMIN_PERMISSIONS.CLUB_POSTS, path: ROUTES.CLUB_POST_NEW},
    {key: ADMIN_PERMISSIONS.CLUB_POSTS, path: ROUTES.CLUB_POST_DETAILS},
    {key: ADMIN_PERMISSIONS.CLUB_POSTS, path: ROUTES.CLUB_POST_EDIT},
    {key: ADMIN_PERMISSIONS.EVENTS, path: ROUTES.EVENTS_MANAGEMENT},
    {key: ADMIN_PERMISSIONS.EVENTS, path: ROUTES.EVENT_DETAILS},
    {key: ADMIN_PERMISSIONS.EVENTS, path: ROUTES.EVENT_EDIT},
    {key: ADMIN_PERMISSIONS.EVENTS, path: ROUTES.EVENT_NEW},
    {key: ADMIN_PERMISSIONS.WORKOUTS, path: ROUTES.WORKOUTS_MANAGEMENT},
    {key: ADMIN_PERMISSIONS.WORKOUTS, path: ROUTES.WORKOUT_CREATE},
    {key: ADMIN_PERMISSIONS.WORKOUTS, path: ROUTES.WORKOUT_DETAILS},
    {key: ADMIN_PERMISSIONS.WORKOUTS, path: ROUTES.WORKOUT_EDIT},
    {key: ADMIN_PERMISSIONS.WORKOUTS, path: ROUTES.PROGRAMS_MANAGEMENT},
    {key: ADMIN_PERMISSIONS.WORKOUTS, path: ROUTES.PROGRAMS_CREATE},
    {key: ADMIN_PERMISSIONS.WORKOUTS, path: ROUTES.PROGRAMS_DETAILS},
    {key: ADMIN_PERMISSIONS.WORKOUTS, path: ROUTES.PROGRAMS_EDIT},
    {key: ADMIN_PERMISSIONS.COMMENTS, path: ROUTES.COMMENTS_MANAGEMENT},
    {key: ADMIN_PERMISSIONS.COMMENTS, path: ROUTES.COMMENTS_BY_TYPE},
]

export const COMMON_ALLOWED_ADMIN_ROUTES = [{key: null, path: ROUTES.DASHBOARD}]
