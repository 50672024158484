import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useQuery} from '@tanstack/react-query'
import {ADMIN, SUPER_ADMIN} from 'constants/roles.constant'
import getApiError from 'utils/getApiError'
import {pushAlert} from 'components/ui'

import {getCategoriesData} from '../CategoriesService'
import { getRole } from 'store/auth/selectors'

const mapCategoriesOptions = (category) =>
    category.map((category) => ({
        value: category.id,
        label: (
            <span style={{color: category.hexColor}}>
                {category.name}
            </span>
        ),
    }))

const useCategoryOptions = (isWorkout) => {
    const role = useSelector(getRole)

    const {data, isFetched, error} = useQuery({
        queryKey: ['categoryOptions'],
        queryFn: getCategoriesData.bind(null, {limit: 1000, offset: 0, sortingOrder: 'Asc', isWorkout: isWorkout}),
        initialData: {data: []},
        retry: 1,
        enabled: role === SUPER_ADMIN,
    })

    useEffect(() => {
        error && pushAlert('danger', getApiError(error))
    }, [error])

    const categoryOptions = mapCategoriesOptions(data.data);

    return {categoryOptions, isLoadingCateforyOptions: role === SUPER_ADMIN ? !isFetched : isFetched}
}

export default useCategoryOptions
