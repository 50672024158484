import {createSelector} from '@reduxjs/toolkit'

import parseApiPermissions from 'utils/parseApiPermissions'
import {SUPER_ADMIN, ADMIN} from 'constants/roles.constant'

export const getAccessToken = (state) => state.auth.session?.accessToken
export const getSignedIn = (state) => state.auth.session?.signedIn
export const getUser = (state) => state.auth.user

export const getIsAuthUser = createSelector(
    [getAccessToken, getSignedIn],
    (accessToken, signedIn) => accessToken && signedIn,
)

export const getRole = (state) => state.auth.user?.role
export const getIsSuperAdmin = (state) => state.auth.user?.role === SUPER_ADMIN
export const getIsAdmin = (state) => state.auth.user?.role === ADMIN
export const getAdminPermissions = (state) => parseApiPermissions(state.auth.user?.adminPermissions)
export const getAdminClub = (state) => state.auth.club
