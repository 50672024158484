import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {Field, useFormikContext, getIn} from 'formik'

import {Select, FormItem} from 'components/ui'
import isInvalid from 'utils/isInvalid'

const SelectField = ({
    name,
    options,
    label,
    placeholder,
    isLoading,
    required,
    disabled,
    isMulti,
    valueFieldKey,
    onChange,
    className,
}) => {
    const {errors, touched, isSubmitting, setFieldValue} = useFormikContext()

    const onSelectChange = (fieldName, option) => {
        // for handling multi select, cause it be different types (single -> object, multi -> array)
        const value = Array.isArray(option) ? option.map((o) => o.value) : option.value
        setFieldValue(fieldName, value)

        if (onChange !== undefined) {
            onChange(option, setFieldValue)
        }
    }

    return (
        <Field name={name}>
            {({field, form}) => (
                <FormItem
                    label={label}
                    labelClass="workout-form-item-label"
                    className={className ?? 'workout-form-item'}
                    invalid={isInvalid(errors, touched, field.name)}
                    errorMessage={getIn(errors, name)}
                    required={required}
                >
                    <Select
                        name={name}
                        isMulti={isMulti}
                        placeholder={placeholder}
                        field={field}
                        form={form}
                        options={options}
                        value={
                            isMulti
                                ? options.filter(
                                      (option) =>
                                          Array.isArray(field.value) &&
                                          field.value.some((val) => {
                                              if (typeof val === 'object' && val !== null) {
                                                  return _.get(val, valueFieldKey) === option.value
                                              }
                                              return val === option.value
                                          }),
                                  )
                                : options.find(({value}) => value === field.value)
                        }
                        onChange={onSelectChange.bind(null, field.name)}
                        isDisabled={isSubmitting || disabled}
                        isLoading={isLoading}
                    />
                </FormItem>
            )}
        </Field>
    )
}

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    isLoading: PropTypes.bool,
}

export default SelectField
