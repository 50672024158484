import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

import countries from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'
countries.registerLocale(en)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)
