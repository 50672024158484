import React from 'react'
import PropTypes from 'prop-types'
import {motion} from 'framer-motion'
import {HiChevronDown} from 'react-icons/hi'

const CollapseToggle = ({isExpanded, onToggle, label}) => {
    return (
        <div className="collapse-toggle" onClick={onToggle}>
            <motion.span
                className="text-lg mt-1"
                initial={{transform: 'rotate(0deg)'}}
                animate={{
                    transform: isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)',
                }}
                transition={{duration: 0.15}}
            >
                <HiChevronDown />
            </motion.span>
            <span className="collapse-toggle-text">{label}</span>
        </div>
    )
}

CollapseToggle.propTypes = {
    expanded: PropTypes.bool,
    onToggle: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default CollapseToggle
