import React from 'react'
import {Field} from 'formik'
import {TfiPlus} from 'react-icons/tfi'
import {AiFillDelete} from 'react-icons/ai'

import {beforeUpload, ACCEPT_IMAGES_TYPES} from 'utils/beforeUpload'
import {validateAfterChangeImageField} from 'utils/validateAfterChangeImageField'
import {Avatar, Upload} from '../ui'

const AvatarField = ({fieldName = 'avatar', fileFieldName = 'avatarFile', prevLogoField, disabled}) => {
    const onSetFormFile = (form, fieldName, file) => {
        form.setFieldValue(fieldName, URL.createObjectURL(file[0]))
        form.setFieldValue(fileFieldName, file[0])
        prevLogoField && form.setFieldValue(prevLogoField, null)
        validateAfterChangeImageField(form, fieldName, URL.createObjectURL(file[0]))
    }

    const clearAvatarField = (form, fieldName, e) => {
        e.stopPropagation()
        form.setFieldValue(fieldName, '')
        form.setFieldValue(fileFieldName, null)
        prevLogoField && form.setFieldValue(prevLogoField, null)
        validateAfterChangeImageField(form, fieldName, '')
    }

    return (
        <Field name={fieldName}>
            {({field, form}) => {
                const avatarProps = field.value ? {src: field.value} : {}
                return (
                    <Upload
                        className={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
                        onChange={onSetFormFile.bind(null, form, field.name)}
                        onFileRemove={onSetFormFile.bind(null, form, field.name)}
                        showList={false}
                        uploadLimit={1}
                        accept={ACCEPT_IMAGES_TYPES.toString()}
                        beforeUpload={beforeUpload}
                        disabled={disabled}
                    >
                        <Avatar
                            size={90}
                            shape="circle"
                            icon={<TfiPlus className="text-xs text-gray-800" />}
                            {...avatarProps}
                        />
                        {field.value && (
                            <div
                                className="avatar-field-delete"
                                onClick={clearAvatarField.bind(null, form, field.name)}
                            >
                                <AiFillDelete />
                            </div>
                        )}
                    </Upload>
                )
            }}
        </Field>
    )
}

export default AvatarField
